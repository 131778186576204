import { Component } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CloudService } from '@services/cloud/cloud.service';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { UserService } from '@services/user/user.service';
import { UserInfo } from 'app/models/users/user-info.model';
import { User } from 'app/models/users/user.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-referral-box',
  templateUrl: './referral-box.component.html',
  styleUrls: ['./referral-box.component.scss']
})
export class ReferralBoxComponent {
  currentUser$: Observable<UserInfo>;

  constructor(
    private userService: UserService,
    private fireAnalytics: AngularFireAnalytics,
    private errorService: ErrorHandlerService,
    private cloudService: CloudService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.currentUser$ = this.userService.userInfo$;
  }



  recaptchaVerify(action: string): void {
    this.recaptchaV3Service.execute(`referralBtn${action}`)
      .subscribe((token) => this.cloudService.recaptchaVerify(token));
  }

  referralShareButtons(shareOption: string): void {
    if (shareOption) {
      this.recaptchaVerify(shareOption);
      try{
        this.fireAnalytics.logEvent('Referral', {
          method: shareOption,
          location: 'ReferralSection',
          action: 'share'
        });
      } catch(e) {
        this.errorService.recordError(e, 'referrals.component.ts',
          'this.fireAnalytics.logEvent()', 'Error al ejecutar track en heap');
      }
      this.fireAnalytics.logEvent(`referral share button clicked`, {method: shareOption, fatal: false});
    } else {
      this.errorService.recordError(
        new Error('Referral Shared Buttons'),
        'referrals.component.ts',
        'referralModalShareButtons()',
        `Ha ocurrido un error al obtener la selección de shared button`
      );
      this.fireAnalytics.logEvent(`referral share button clicked`, {method: '', fatal: true});
    }
  }

  showSuccefullCopyText() {
    const copyBtn = document.getElementById('referral-component-copy-link-button-modal');
    copyBtn.setAttribute('data-copied-modal', 'true');
    const copiedBtn = document.getElementById('copied-modal');
    copiedBtn.setAttribute('data-copied-modal', 'false');
    setTimeout(() => {
      copyBtn.setAttribute('data-copied-modal', 'false');
      copiedBtn.setAttribute('data-copied-modal', 'true');
    }, 3000);
  }

  copyToClipboard(currentUser: User) {
    const userId = currentUser.id;
    // Create an auxiliary hidden input
    const aux = document.createElement('input');
    // Get the text from the element passed into the input
    aux.setAttribute('value', this.generateReferralLink(currentUser));
    // Append the aux input to the body
    document.body.appendChild(aux);
    // Highlight the content
    aux.select();
    // Execute the copy command
    document.execCommand('copy');
    // Remove the input from the body
    document.body.removeChild(aux);
    try{
      this.fireAnalytics.logEvent('Referral', {
        method: 'Clipboard',
        url: `https://app.neatpagos.com/registrar?invitedBy=${  userId}`,
        location: 'ReferralSection',
        action: 'share'
      });
    } catch(e) {
      this.errorService.recordError(e, 'referrals.component.ts',
        'this.fireAnalytics.logEvent()', 'Error al ejecutar track en heap');
    }
  }

  generateReferralLink(currentUser: User): string {
    const userId = currentUser.id;
    return (`https://app.neatpagos.com/registrar?invitedBy=${  userId}`);
  }

}
