import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RentListService } from '@services/rent-list/rent-list.service';
import { EntityTypeLocale, ProfessionalService } from '@shared/enums/enums.enum';

enum UtilityCategoriesLocale {
  Agua = 'assets/img/basic-services-icons/water.svg',
  Luz = 'assets/img/basic-services-icons/electricity.svg',
  Gas = 'assets/img/basic-services-icons/naturalGas.svg',
  Telefonía = 'assets/img/basic-services-icons/telephony.svg',
  Internet = 'assets/img/basic-services-icons/internetAndCable.svg',
  Autopistas = 'assets/img/basic-services-icons/highways.svg',
  Otros = 'assets/img/basic-services-icons/other.svg',
  MyV = 'assets/img/basic-services-icons/myvHipotecarios.svg',
  Afianza = 'assets/img/basic-services-icons/afianza.svg'
}

@Component({
  selector: 'app-select-entity-for-automate',
  templateUrl: './select-entity-for-automate.component.html',
  styleUrls: ['./select-entity-for-automate.component.scss']
})
export class SelectEntityForAutomateComponent  implements OnInit {
  entities: any;
  utilityCategoriesLocale = UtilityCategoriesLocale;
  entityTypeLocale = EntityTypeLocale;
  professionalServices = ProfessionalService;
  hideAddEntityBtn = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectEntityForAutomateComponent>,
    private rentListService: RentListService,
    private router: Router,
  ) {
    this.data.hideAddEntityBtn && (this.hideAddEntityBtn = true);
  }
  ngOnInit(): void {
    this.entities = this.data.entities;
  }

  cancel(): void {
    this.dialogRef.close();
  }


  async goToCreateEntity() {
    this.router.navigate(['/dashboard/pagos/nuevo']).then(() => this.dialogRef.close());
  }

  async goToConfigure(entity: any) {
    const openAutomateOption = this.hideAddEntityBtn ? {queryParams: {automate: true}} : {};
    this.rentListService.currentEntityForConfig = entity;
    this.router.navigate(['/dashboard/configurar-cuenta', entity?.id], openAutomateOption).then(() => this.dialogRef.close());
  }
}