import { Component, Inject, isDevMode } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@services/user/user.service';
import { User } from 'app/models/users/user.model';
import { DialogService } from '@services/shared/dialog/dialog.service';


export enum HowYouKnowUsResponses {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Linkedin = 'Linkedin',
  recommended = 'Me lo recomendó un conocido',
}

export enum ReasonToUse {
  benefits = 'Acumular beneficios con mi tarjeta de crédito',
  automatization = 'Quiero automatizar el pago de mis cuentas',
  needMoney = 'No tengo plata y necesito ponerme al día',
  others = 'otros',
}

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss']
})
export class WelcomeModalComponent {
  currentUser: User;
  specialReferrers = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WelcomeModalComponent>,
    private userService: UserService,
    private dialogService: DialogService,
  ) {
    this.currentUser = this.userService.currentUser;
    this.getSpecialReferrers();
  }


  next() {
    this.dialogService.openReferralModal(ReasonToUse.benefits);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getSpecialReferrers() {
    if (isDevMode()) {
      this.specialReferrers = ['R1XDaxW1xVhRGEBLZOvb1Orei7v1'];
    } else {
      this.specialReferrers = ['0C6uhRT0xIZgHsEbxvmpplYkMHP2'];
    }
  }


}
