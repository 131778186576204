<div class="text-center">
    <h2 class="title-modal">
        Se actualizará la tarjeta en los siguientes pagos automáticos. 
    </h2>
    <div class="w-100 m-auto" style="max-width: 400px;" *ngFor="let entity of entities;">
        <div *ngIf="entity.entityType === 'basicService'; else normalEntity" class="w-100 d-flex align-items-center my-3 py-4 px-2 px-md-5 entity-row mobile-center">
            <div class="text-left">
                <div class="vertical-center-img img-entity d-none d-md-flex">
                    <img src="assets/img/basic-services-icons/{{utilityCategoriesLocale[entity.category.split('/')[0]]}}.svg" class="img-entity">
                </div>
            </div>
            <div class="ml-0 ml-md-2 text-left">
              <div class="d-block">
                <span class="primary-text">{{entity.alias ? entity.alias : entity.utilityName}} </span>
              </div>
              <div class="d-block">
                <span class="secundary-text">N° de cuenta</span>: <span class="secundary-text">{{ entity.clientNumber }}</span>
              </div>
            </div>
        </div>
        <ng-template #normalEntity>
            <div class="w-100 d-flex align-items-center my-3 py-4 px-2 px-md-5 entity-row mobile-center">
                <div class="text-left">
                    <div class="vertical-center-img img-entity d-none d-md-flex">
                        <img *ngIf="!entity.customData" src="assets/img/entity-icons/{{entity?.entityType}}.svg" style="max-width: 100%; max-height: 100%;" alt="{{ entity?.showEntityTypeLocale()}}">
                        <img *ngIf="entity?.customData && entity?.customData.providerName && ['Security Principal', 'Hipotecaria Security', 'Tango', 'Club de Polo San Cristóbal'].includes(entity?.customData.providerName)" src="assets/img/entity-icons/{{entity?.customData.providerName}}.svg" style="max-width: 100%; max-height: 100%;" alt="{{ entity?.showEntityTypeLocale()}}">
                    </div>
                </div>
                <div class="ml-0 ml-md-3 text-left">
                  <div class="d-block">
                    <span class="primary-text">{{!entity.customData ? entityTypeLocale[entity.entityType] : entity?.ownerFullName}} </span>
                  </div>
                  <div *ngIf="entity.referenceAddress" class="d-block">
                   <span class="secundary-text">{{ entity.referenceAddress }}</span>
                  </div>
                  <div *ngIf="entity.category && entity.entityType === 'others'" class="d-block">
                   <span class="secundary-text"><span class="primary-text">Servicio</span>: {{ entity.category }}</span>
                  </div>
                  <div *ngIf="entity.category && entity.entityType === 'professionals'" class="d-block">
                   <span class="secundary-text"><span class="primary-text">Servicio</span>: {{ professionalServices[entity.category] }}</span>
                  </div>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="d-flex justify-content-between my-2">
        <button mat-raised-button (click)="confirm()" class="confirm-btn">Aceptar</button>
        <button mat-raised-button (click)="cancel()" class="off-white-btn">Cancelar</button>
    </div>
</div>