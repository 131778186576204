<section *ngIf="currentUser" id="welcome-modal" class="row animated fadeIn">
    <span style="margin-left:auto; margin-right: 10px;" id="welcome-modal-closed" class="d-none d-md-block" (click)="onNoClick()"><mat-icon class="c-pointer">close</mat-icon></span>
    <div class="col-12 col-md-12 mx-auto">
        <mat-card class="mat-elevation-z0 neumorphic">
            <mat-card-content class="px-lg-3">
                <div>
                    <div class="info-container">
                        <div class="d-flex">
                            <div class="text-container">
                                <div class="dialog-header title text-left">
                                    <mat-card-title class="neat-text pt-2 pb-1 pb-md-3 px-md-2">¡Hola {{currentUser.firstNames}}!</mat-card-title>
                                    <span style="margin-left:auto; margin-right: 10px;" id="welcome-modal-closed" class="d-md-none" (click)="onNoClick()"><mat-icon class="c-pointer">close</mat-icon></span>
                                </div>
                                <ng-container *ngIf="!currentUser.referrerId; else referredUser">
                                    <mat-card-subtitle class="text-left">
                                        <p>
                                            Comienza a usar neat y podrás: 
                                        </p>
                                        <ul>
                                            <li>
                                                Aumentar los beneficios (puntos, millas, etc) de tu tarjeta de crédito. 
                                            </li>
                                            <li>
                                                Organizar el pago de tus cuentas y no atrasarte. 
                                            </li>
                                            <li>
                                                Ahorrar tiempo automatizando el pago de cuentas. 
                                            </li>
                                            <li>
                                                Mantener tus lukas en tu cuenta corriente. 
                                            </li>
                                            <li>
                                                Pagar tu arriendo en UF sin hacer cálculos.
                                            </li>
                                        </ul>
                                    </mat-card-subtitle>
                                    <div class="d-flex mt-2 mt-md-4" style="flex-wrap: wrap-reverse;">
                                        <button mat-raised-button id="continue-btn-users" class="main-btn" style="width: fit-content !important; z-index: 11111;" (click)="next()">Invita a tus amigos y gana descuentos</button>
                                    </div>
                                </ng-container>
                                <ng-template #referredUser>
                                    <mat-card-subtitle>
                                        <p id="paragraph-1-welcome-modal" style="display: block;">
                                            Nos alegra darte la bienvenida a Neat, has sido referido y ahora podrás disfrutar {{specialReferrers.includes(currentUser?.referrerId) ? 'un 25% de descuento por 3 meses en nuestra comisión.' : ' en tu primer pago un 50% de descuento en nuestra comisión.'}}<b></b>
                                        </p>
                                        <p id="paragraph-2-welcome-modal">
                                            Además podrás empezar a multiplicar los beneficios de tus tarjetas de crédito.
                                        </p>
                                    </mat-card-subtitle>
                                    <div class="d-flex" style="flex-wrap: wrap-reverse; justify-content: flex-end;">
                                        <button mat-raised-button id="continue-btn-for-referred-users" class="main-btn" style="width: fit-content !important; z-index: 11111;" (click)="next()">GANAR BENEFICIOS</button>
                                    </div>
                                </ng-template>
                            </div>
                            <img class="img-fluid d-none d-sm-block" width="180" src="assets/img/illustrations/welcome-alt.png" alt="welcome" style="position: absolute; right: -24px; bottom: -24px;">
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</section>
