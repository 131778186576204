import { MergeTypes } from '@shared/utils';
import { INeatServices } from 'neat-lib';
import { BasicService } from 'app/models/abstract-basic-service.model';
import { Entity } from 'app/models/abstract-entity.model';

export const getImageUrlFromService = (entity: MergeTypes<BasicService, Entity>, neatServices: INeatServices[]) => {
  if (!entity.neatInformation?.serviceNeatCode) {return null;}
  const neatService = neatServices.find(service => service.neatCode === entity.neatInformation.serviceNeatCode);
  if (!neatService) {return null;}
  return neatService.logoUrl;
};