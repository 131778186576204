import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '@services/shared/dialog/dialog.service';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss']
})
export class ReferralsModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReferralsModalComponent>,
    private dialogService: DialogService,
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  nextModal() {
    this.dialogRef.close();
    this.dialogService.openSurveyResponseModal(this.data);
  }

}
