<section id="welcome-modal" class="row animated fadeIn">
    <span style="margin-left:auto; margin-right: 10px;" id="onboarding-survey-closed" (click)="onNoClick()"><mat-icon class="c-pointer">close</mat-icon></span>
    <div class="col-12 col-md-12 mx-auto">
        <mat-card class="mat-elevation-z0 neumorphic">
            <mat-card-content class="px-lg-3">
                <div>
                    <div class="info-container">
                        <div class="d-flex">
                            <div class="text-container text-center">
                                <div class="dialog-header title text-center">
                                    <mat-card-title class="neat-text pt-2 pb-3 px-md-2 text-center m-auto">Refiere a tus amigos</mat-card-title>
                                </div>
                                <mat-card-subtitle class="text-left">
                                    <ul>
                                        <li>
                                            Gana el total de la primera comisión que paguen tus amigos referidos. 
                                        </li>
                                        <li>
                                            Gana la mitad de la segunda comisión que paguen tus amigos referidos. 
                                        </li>
                                        <li>
                                            Ellos recibirán un 50% de descuento en su primera comisión. 
                                        </li>
                                    </ul>
                                </mat-card-subtitle>
                                <div>
                                   <app-referral-box></app-referral-box>
                                </div>
                                <div>
                                    <span class="d-block mt-3 mb-4" style="font-size: 12px;
                                    line-height: 120%;
                                    text-align: center;
                                    letter-spacing: 0.004em;
                                    text-transform: uppercase;
                                    color: #848484;">Tienen que registrarse con tu link.</span>
                                </div>
                                <div class="d-flex mt-4 justify-content-center" style="flex-wrap: wrap-reverse;">
                                    <button mat-raised-button id="close-referral-modal" class="mb-3 secundary-btn mr-0 mr-md-4" style="width: fit-content !important;" (click)="onNoClick()">CERRAR</button>
                                </div>
                            </div>
                            <img class="img-fluid d-none d-sm-block" width="180" src="assets/img/illustrations/welcome-alt.png" alt="welcome" style="position: absolute; right: -24px; bottom: -24px;">
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</section>
