import { Overlay } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IPaymentMethod } from 'neat-lib/dist/Interfaces/IData';

import { IVerification, MergeTypes } from '@shared/utils';
import { AutomatePaymentComponent } from 'app/dashboard/shared/automate-payment/automate-payment.component';
import { BasicServiceHelpModalComponent } from 'app/dashboard/shared/basic-service-help-modal/basic-service-help-modal.component';
import { BasicServicesByRutModalComponent } from 'app/dashboard/shared/basic-services-by-rut-modal/basic-services-by-rut-modal.component';
import {
  BasicServicesPaymentModalComponent
} from 'app/dashboard/shared/basic-services-payment-modal/basic-services-payment-modal.component';
import {
  ConfirmChangeCardsForAutomateComponent
} from 'app/dashboard/shared/confirm-change-cards-for-automate/confirm-change-cards-for-automate.component';
import { DeleteUserModalComponent } from 'app/dashboard/shared/delete-user-modal/delete-user-modal.component';
import { DeletedCreditCardSuccessComponent } from 'app/dashboard/shared/deleted-credit-card-success/deleted-credit-card-success.component';
import { DepositeeInfoModalComponent } from 'app/dashboard/shared/depositee-info-modal/depositee-info-modal.component';
import { EntityAddedModalComponent } from 'app/dashboard/shared/entity-added-modal/entity-added-modal.component';
import { InvoicesSummaryModalComponent } from 'app/dashboard/shared/invoices-summary-modal/invoices-summary-modal.component';
import { KushkiEnrollModalComponent } from 'app/dashboard/shared/kushki-enroll-modal/kushki-enroll-modal.component';
import { MagicClipboardModalComponent } from 'app/dashboard/shared/magic-clipboard-modal/magic-clipboard-modal';
import { CmrPaymentFailedComponent } from 'app/dashboard/shared/modals-components/cmr-payment-failed/cmr-payment-failed.component';
import { OnboardingResponseComponent } from 'app/dashboard/shared/modals-components/onboarding-response/onboarding-response.component';
import { ReferralsModalComponent } from 'app/dashboard/shared/modals-components/referrals/referrals.component';
import { RetryTransferModalComponent } from 'app/dashboard/shared/modals-components/retry-transfer/retry-transfer.component';
import { NeatClubModalComponent } from 'app/dashboard/shared/neat-club-modal/neat-club-modal.component';
import { NeatClubTermsComponent } from 'app/dashboard/shared/neat-club-terms/neat-club-terms.component';
import {
  NeatEntityPaymentSuccessModalComponent
} from 'app/dashboard/shared/neat-entity-payment-success-modal/neat-entity-payment-success-modal.component';
import { NeatPrimeModalComponent } from 'app/dashboard/shared/neat-prime-modal/neat-prime-modal.component';
import { NewCheckoutPresentationComponent } from 'app/dashboard/shared/new-checkout-presentation/new-checkout-presentation.component';
import {
  PaymentMethodsSelectionModalComponent
} from 'app/dashboard/shared/payment-methods-selection-modal/payment-methods-selection-modal.component';
import { PinModalComponent } from 'app/dashboard/shared/pin-modal/pin-modal.component';
import { PromotionModalComponent } from 'app/dashboard/shared/promotion-modal/promotion-modal.component';
import { ReminderModalComponent } from 'app/dashboard/shared/reminder-modal/reminder-modal.component';
import { RewardExchangeModalComponent } from 'app/dashboard/shared/reward-exchange-modal/reward-exchange-modal.component';
import { SelectEntityForAutomateComponent } from 'app/dashboard/shared/select-entity-for-automate/select-entity-for-automate.component';
import { TagTotalSummaryModalComponent } from 'app/dashboard/shared/tag-total-summary-modal/tag-total-summary-modal.component';
import {
  UpdateAutomaticPaymentMethodComponent
} from 'app/dashboard/shared/update-automatic-payment-method/update-automatic-payment-method.component';
import { VerificationStatusModalComponent } from 'app/dashboard/shared/verification-status-modal/verification-status-modal.component';
import { VerifyIdentityModalComponent } from 'app/dashboard/shared/verify-identity-modal/verify-identity-modal.component';
import { WelcomeModalComponent } from 'app/dashboard/shared/welcome-modal/welcome-modal.component';
import { IDebtSummary } from 'app/interfaces/basic-services.interface';
import { LoginWithoutPasswordComponent } from 'app/landing/shared/login-without-password/login-without-password.component';
import { BasicService } from 'app/models/abstract-basic-service.model';
import { Entity } from 'app/models/abstract-entity.model';
import { User } from 'app/models/users/user.model';

import { RutModalComponent } from '../../../dashboard/shared/rut-modal/rut-modal.component';
import { Payment } from '../../../models/abstract-payment.model';
import { PasswordResetComponent } from './../../../landing/shared/password-reset/password-reset.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<any>, public overlay: Overlay) { }

  openPromotionDialog() {
    this.dialog.open(PromotionModalComponent, {
      maxWidth: '100%',
      minWidth: '75%',
      width: '1300px',
      autoFocus: false,
      maxHeight: '100vh',
      panelClass: ['neat-promotion-dialog-container', 'dialog-modal-border-radius'],
    });
  }

  openPasswordResetDialog() {
    this.dialog.open(PasswordResetComponent, {
      maxWidth: '95vw',
      panelClass: 'dialog-modal-border-radius',
      width: '450px'
    });
  }

  openLoginWithLink() {
    this.dialog.open(LoginWithoutPasswordComponent, {
      maxWidth: '95vw',
      panelClass: 'dialog-modal-border-radius',
      width: '450px'
    });
  }

  openPinValidateDialog() {
    return this.dialog.open(PinModalComponent, {
      maxWidth: '95vw',
      width: '450px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'dialog-modal-border-radius',
    });
  }

  openInvoicesDialog(debtSummary: IDebtSummary) {
    return this.dialog.open(InvoicesSummaryModalComponent, {
      maxWidth: '95vw',
      width: '450px',
      //disableClose: true,
      autoFocus: false,
      data: debtSummary,
      panelClass: 'dialog-modal-border-radius',
    });
  }

  openTagTotalDialog(debtSummary: IDebtSummary) {
    return this.dialog.open(TagTotalSummaryModalComponent, {
      maxWidth: '95vw',
      width: '450px',
      //disableClose: true,
      autoFocus: false,
      data: debtSummary,
      panelClass: 'dialog-modal-border-radius',
    });
  }

  openEntityAddedDialog(savedEntity: any) {
    return this.dialog.open(EntityAddedModalComponent, {
      maxWidth: '95vw',
      width: '500px',
      disableClose: true,
      autoFocus: false,
      data: savedEntity,
      panelClass: 'dialog-modal-border-radius',
    });
  }

  openPaymentSuccess(data: { category: string, entity: Entity, transferDate?: string }) {
    return this.dialog.open(NeatEntityPaymentSuccessModalComponent, {
      maxWidth: '95vw',
      width: '500px',
      autoFocus: false,
      data: data,
      panelClass: 'dialog-modal-border-radius',
    });
  }

  openBasicServicesByRut(data: { rut: string, selectedBasicServices: any[] }, isMobile: boolean) {
    return this.dialog.open(BasicServicesByRutModalComponent, {
      id: 'cuentas-por-rut',
      maxWidth: '95vw',
      width: '600px',
      maxHeight: isMobile && '100dvh',
      autoFocus: false,
      data: data,
      disableClose: true,
      panelClass: ['cuenta-por-rut', 'dialog-modal-border-radius'],
      //scrollStrategy: !isMobile && this.overlay.scrollStrategies.noop()
    });
  }

  openPaymentMethodsDialog(paymentMethods: any, selectedCard: any, isMobile: boolean, entities: MergeTypes<BasicService, Entity>[]) {
    return this.dialog.open(PaymentMethodsSelectionModalComponent, {
      maxWidth: '100vw',
      maxHeight: '75vh',
      width: '470px',
      height: isMobile ? '75vh' : '640px',
      disableClose: true,
      autoFocus: false,
      data: { paymentMethods: paymentMethods, selectedCard: selectedCard, entities: entities },
      panelClass: ['dialog-modal-border-radius', 'payment-methods-modal'],
    });
  }

  openRutModal(url?: string) {
    this.dialog.open(RutModalComponent, {
      maxWidth: '95vw',
      width: '450px',
      data: { url },
      disableClose: true,
      panelClass: 'dialog-modal-border-radius',
    });
  }

  openRutModalCustomProvider() {
    return this.dialog.open(RutModalComponent, {
      maxWidth: '95vw',
      width: '450px',
      disableClose: true,
      data: { edipro: true },
      panelClass: 'dialog-modal-border-radius',
    });
  }

  openWelcomeModal(user: User) {
    this.dialog.open(WelcomeModalComponent, {
      maxWidth: '100%',
      minWidth: '50%',
      data: { user },
      panelClass: ['onboarding-modal-auto', 'dialog-modal-border-radius'],
    });
  }

  openReferralModal(response: string) {
    this.dialog.open(ReferralsModalComponent, {
      maxWidth: '100%',
      minWidth: '50%',
      data: response,
      panelClass: ['onboarding-modal-auto', 'dialog-modal-border-radius'],
    });
  }

  openSurveyResponseModal(response: string) {
    this.dialog.open(OnboardingResponseComponent, {
      maxWidth: '100%',
      minWidth: '25%',
      maxHeight: '100vh',
      width: '280px',
      data: response,
      panelClass: ['onboarding-modal', 'dialog-modal-border-radius'],
    });
  }

  neatPrimeModal() {
    this.dialog.open(NeatPrimeModalComponent, {
      maxWidth: '100%',
      minWidth: '50%',
      maxHeight: '100vh',
      panelClass: ['dialog-modal-border-radius', 'dialog-modal-border-radius'],
    });
  }

  openDeleteUserModal(userId: string) {
    this.dialog.open(DeleteUserModalComponent, {
      maxWidth: '100%',
      minWidth: '30%',
      maxHeight: '100vh',
      width: '580px',
      hasBackdrop: true,
      panelClass: ['neat-payment-summary-modal', 'dialog-modal-border-radius'],
      data: {
        userId
      },
      disableClose: true
    });
  }

  openBasicServicePayment(payment: any, deltaValues?: any[]) {
    this.dialog.open(BasicServicesPaymentModalComponent, {
      maxWidth: '810px',
      maxHeight: '100vh',
      width: '100%',
      hasBackdrop: true,
      panelClass: 'dialog-modal-border-radius',
      data: { payment, deltaValues },
      disableClose: true
    });
  }

  confirmChangePreferredCardForAutomate(entities: any) {
    return this.dialog.open(ConfirmChangeCardsForAutomateComponent, {
      maxWidth: '420px',
      maxHeight: '100vh',
      width: '100%',
      hasBackdrop: true,
      panelClass: 'dialog-modal-border-radius',
      data: { entities },
      disableClose: true
    });
  }

  selectEntityForAutomatizate(entities: Entity | BasicService, hideAddEntityBtn?: boolean) {
    this.dialog.open(SelectEntityForAutomateComponent, {
      maxWidth: '450px',
      maxHeight: '90vh',
      width: '100%',
      hasBackdrop: true,
      panelClass: 'dialog-modal-border-radius',
      data: { entities, hideAddEntityBtn },
      disableClose: true
    });
  }

  openAutomatePaymentComponent() {
    return this.dialog.open(AutomatePaymentComponent, {
      maxWidth: '100%',
      minWidth: '50%',
      maxHeight: '100vh',
      width: '780px',
      hasBackdrop: true,
      disableClose: true,
      panelClass: ['automate-payment', 'dialog-modal-border-radius'],
    });
  }

  openReminderComponent(entityTypeLocale: string, currentTransferDate: number) {
    return this.dialog.open(ReminderModalComponent, {
      maxWidth: '100%',
      maxHeight: '100vh',
      width: '400px',
      hasBackdrop: true,
      data: { entityTypeLocale, currentTransferDate },
      panelClass: ['dialog-modal-border-radius'],
    });
  }


  openNewCheckoutPresentation() {
    return this.dialog.open(NewCheckoutPresentationComponent, {
      maxWidth: '100%',
      maxHeight: '100vh',
      width: '600px',
      hasBackdrop: true,
      panelClass: ['dialog-modal-border-radius'],
    });
  }


  openDepoiteeDataModal(entity: Entity) {
    return this.dialog.open(DepositeeInfoModalComponent, {
      maxWidth: '100%',
      maxHeight: '100vh',
      width: '450px',
      hasBackdrop: true,
      data: { entity },
      panelClass: ['dialog-modal-border-radius'],
    });
  }


  openBasicServiceHelpModal() {
    return this.dialog.open(BasicServiceHelpModalComponent, {
      maxWidth: '100%',
      maxHeight: '100vh',
      width: '600px',
      hasBackdrop: true,
      panelClass: ['dialog-modal-border-radius'],
    });
  }

  deleteCreditCardWithAutomaticPaymentsAssigned(
    entities: MergeTypes<BasicService, Entity>[], currentUserIdToken: string, currentPath: string) {
    return this.dialog.open(UpdateAutomaticPaymentMethodComponent, {
      maxWidth: '450px',
      maxHeight: '100vh',
      width: '100%',
      hasBackdrop: true,
      panelClass: 'dialog-modal-border-radius',
      data: { entities, currentUserIdToken, currentPath },
      disableClose: true
    });
  }

  deleteCreditCardWithAutomaticEntitiesRelatedSuccess(paymentMethod: IPaymentMethod) {
    this.dialog.open(DeletedCreditCardSuccessComponent, {
      maxWidth: '420px',
      maxHeight: '100vh',
      width: '100%',
      hasBackdrop: true,
      panelClass: 'dialog-modal-border-radius',
      data: { paymentMethod },
      disableClose: true
    });
  }

  openKushkiDialog(isMobile: boolean) {
    this.dialog.open(KushkiEnrollModalComponent, {
      maxWidth: '100vw',
      maxHeight: '75vh',
      width: '470px',
      height: isMobile ? '75vh' : '640px',
      disableClose: true,
      autoFocus: false,
      panelClass: ['dialog-modal-border-radius', 'payment-methods-modal'],
    });
  }

  openRetryTransferDialog(isMobile: boolean, payment: Payment) {
    this.dialog.open(RetryTransferModalComponent, {
      maxWidth: '100vw',
      maxHeight: '75vh',
      width: '500px',
      height: 'auto',
      disableClose: true,
      autoFocus: false,
      data: { payment },
      panelClass: ['dialog-retry-transfer'],
    });
  }

  openMagicClipboardDialog(userInfo, formGroup) {
    return this.dialog.open(MagicClipboardModalComponent, {
      maxWidth: '100vw',
      maxHeight: '75vh',
      width: '500px',
      height: 'auto',
      disableClose: true,
      autoFocus: false,
      data: { userInfo, formGroup },
      panelClass: 'dialog-modal-border-radius',
    });
  }

  openVerificationModalStatus(verificationData: IVerification) {
    return this.dialog.open(VerificationStatusModalComponent, {
      maxWidth: '95vw',
      width: '400px',
      disableClose: true,
      autoFocus: false,
      data: verificationData,
      panelClass: 'dialog-modal-border-radius',
    });
  }

  openSoyIoModalVerification() {
    return this.dialog.open(VerifyIdentityModalComponent, {
      maxWidth: '95vw',
      width: '400px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'dialog-modal-border-radius',
    });
  }

  openNeatClubDialog(user: User) {
    return this.dialog.open(NeatClubModalComponent, {
      maxWidth: '95vw',
      width: '500px',
      panelClass: ['neat-club-modal', 'dialog-modal-border-radius'],
      disableClose: true,
      autoFocus: false,
      data: { user },
    });
  }

  openNeatClubTermsModal(isMobile: boolean) {
    return this.dialog.open(NeatClubTermsComponent, {
      maxWidth: '100%',
      maxHeight: '90vh',
      height: isMobile ? '75vh' : '850px',
      disableClose: true,
      width: '550px',
      hasBackdrop: true,
      panelClass: ['dialog-modal-border-radius', 'neat-club-terms-modal'],
    });
  }

  openRewardsExchangeConfirm(isMobile: boolean, rewardSelected: any) {
    return this.dialog.open(RewardExchangeModalComponent, {
      maxWidth: '100%',
      maxHeight: '450px',
      height: isMobile ? '75vh' : '850px',
      disableClose: true,
      width: '450px',
      hasBackdrop: true,
      data: { rewardSelected },
      panelClass: ['dialog-modal-border-radius', 'neat-club-terms-modal'],
    });
  }

  openCmrPaymentFailedModal() {
    this.dialog.open(CmrPaymentFailedComponent, {
      maxWidth: '100vw',
      width: '575px',
      height: 'auto',
      disableClose: true,
      autoFocus: false,
      panelClass: ['dialog-retry-transfer'],
    });
  }

  closeAll() {
    this.dialog.closeAll();
  }
}
