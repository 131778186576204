<app-loading [showLoader]="showLoading"></app-loading>
<div *ngIf="!affectedEntities; else openAffectedEntities">
    <div class="d-flex justify-content-end mb-2">
        <span id="close-modal-delete-credit-card-with-automatic-payment-associated" (click)="close()"><mat-icon style="color: #3B4086; cursor: pointer;">close</mat-icon></span>
    </div>
    <div>
        <h2 class="title">Esta tarjeta está asociada a pagos automáticos</h2>
    </div>
    <div>
        <span class="d-block link-text mt-2 mb-3 text-center" (click)="seeAffectedEntities()">Ver pagos automáticos asociados a la tarjeta</span>
    </div>
    <div *ngIf="(paymentMethods$ | async).length > 0; else noCreditCardAdded">
       <p class="main-text">Selecciona otro de tus métodos de pago para que se asigne a tus pagos automáticos.</p>
    </div>
    <ng-template #noCreditCardAdded>
        <p class="main-text d-block mt-4">Agrega un nuevo medio de pago para que se asigne a tus pagos automáticos.</p>
    </ng-template>
    <div>
        <ng-container *ngIf="(paymentMethods$ | async) as paymentMethods">
            <div class="text-center row custom-wrap">
            <div *ngFor="let cards of paymentMethods; let i = index" class="col-6 mb-2 mb-md-3 mt-auto custom-width" [ngClass]="formatBankNameClass(cards?.bankName, cards.cardType)">
                <div (click)="selectPaymentMethod(cards.id);" class="payment-method-box-small w-100">
                    <div style="cursor: pointer;">
                        <img *ngIf="formatBankName(cards?.bankName)" src="assets/img/payment-methods-logos-mobile/{{formatBankName(cards?.bankName, cards.cardType)}}.svg" class="img-fluid img-entity mr-2" style="width: 36px;" alt="{{cards.cardType}}">
                        <img *ngIf="!formatBankName(cards?.bankName)" src="assets/payments-methods-section-icons/{{cards.cardType}}.svg" class="img-fluid img-entity mr-2" style="width: 36px; height: 24px;" alt="{{cards.cardType}}">
                    </div>
                    <div class="card-data" style="display: flex; flex-direction: column; align-items: baseline; cursor: pointer;">
                        <span class="card-brand mb-2">{{cards.cardType ? cards?.brand ? cards.brand.charAt(0).toUpperCase() + cards.brand.slice(1).toLowerCase() : cardTypes[cards.cardType] : cardTypes[cards.cardType]}}</span>
                        <span *ngIf="cards?.brand && cards?.brand.toLowerCase().replace(' ', '') !== cards.cardType.toLowerCase().replace(' ', '')" class="card-type mb-2">{{cards.cardType && cardTypes[cards.cardType] ? cardTypes[cards.cardType] : cards.cardType}}</span>
                        <span *ngIf="cards?.brand && cards?.brand.toLowerCase().replace(' ', '') === cards.cardType.toLowerCase().replace(' ', '')" class="card-type mb-2">Crédito</span>
                        <span class="card-number">{{formatCreditCardNumbers(cards.cardNumber)}}</span>
                    </div>
                </div>
            </div>
        </div>
        </ng-container>
    </div>
    <div  class="w-100 d-flex align-items-center justify-content-center my-3 py-4 px-2 px-md-5 entity-row mobile-center">
        <span class="add-entity-text d-flex" id="add-payment-method-from-update-payment-method-modal" (click)="creditCardRegistration()" style="cursor: pointer;"><img src="assets/img/icons/add_circle_outline.svg" class="mr-2">Agregar otro medio de pago</span>
    </div>
</div>
<ng-template #openAffectedEntities>
    <div class="text-center">
        <div class="d-flex justify-content-end mb-2">
            <span id="close-modal-delete-credit-card-see-affected-entities" (click)="seeAffectedEntities()"><mat-icon style="color: #3B4086; cursor: pointer;">close</mat-icon></span>
        </div>
        <h2 class="title-modal">
            Pagos automáticos que se verán afectados: 
        </h2>
        <div class="w-100 m-auto" style="max-width: 400px;" *ngFor="let entity of entities;">
            <div *ngIf="entity.entityType === 'basicService'; else normalEntity" class="w-100 d-flex align-items-center my-3 py-4 px-2 px-md-5 entity-row mobile-center">
                <div class="text-left">
                    <div class="vertical-center-img img-entity d-none d-md-flex">
                        <img src="assets/img/basic-services-icons/{{utilityCategoriesLocale[entity.category.split('/')[0]]}}.svg" class="img-entity">
                    </div>
                </div>
                <div class="ml-0 ml-md-2 text-left">
                  <div class="d-block">
                    <span class="primary-text">{{entity.alias ? entity.alias : entity.utilityName}} </span>
                  </div>
                  <div class="d-block">
                    <span class="secundary-text">N° de cuenta</span>: <span class="secundary-text">{{ entity.clientNumber }}</span>
                  </div>
                </div>
            </div>
            <ng-template #normalEntity>
                <div class="w-100 d-flex align-items-center my-3 py-4 px-2 px-md-5 entity-row mobile-center">
                    <div class="text-left">
                        <div class="vertical-center-img img-entity d-none d-md-flex">
                            <img *ngIf="!entity.customData" src="assets/img/entity-icons/{{entity?.entityType}}.svg" style="max-width: 100%; max-height: 100%;" alt="{{ entity?.showEntityTypeLocale()}}">
                            <img *ngIf="entity?.customData && entity?.customData.providerName && ['Security Principal', 'Hipotecaria Security', 'Tango', 'Club de Polo San Cristóbal'].includes(entity?.customData.providerName)" src="assets/img/entity-icons/{{entity?.customData.providerName}}.svg" style="max-width: 100%; max-height: 100%;" alt="{{ entity?.showEntityTypeLocale()}}">
                        </div>
                    </div>
                    <div class="ml-0 ml-md-3 text-left">
                      <div class="d-block">
                        <span class="primary-text">{{!entity.customData ? entityTypeLocale[entity.entityType] : entity?.ownerFullName}} </span>
                      </div>
                      <div *ngIf="entity.referenceAddress" class="d-block">
                       <span class="secundary-text">{{ entity.referenceAddress }}</span>
                      </div>
                      <div *ngIf="entity.category && entity.entityType === 'others'" class="d-block">
                       <span class="secundary-text"><span class="primary-text">Servicio</span>: {{ entity.category }}</span>
                      </div>
                      <div *ngIf="entity.category && entity.entityType === 'professionals'" class="d-block">
                       <span class="secundary-text"><span class="primary-text">Servicio</span>: {{ professionalServices[entity.category] }}</span>
                      </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="d-flex justify-content-center my-2">
            <button mat-raised-button (click)="seeAffectedEntities()" class="confirm-btn"><span>Volver</span></button>
        </div>
    </div>
</ng-template>