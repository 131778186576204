<div class="text-center">
    <span class="d-flex mb-3 justify-content-end" style="cursor: pointer;" (click)="cancel()">
        <mat-icon class="modal-close-icon">close</mat-icon>
    </span>
    <span class="title-modal">
        Selecciona la cuenta que quieres automatizar
    </span>
    <div *ngIf="entities.length > 0">
        <div class="w-100 m-auto" style="max-width: 400px; cursor: pointer;" *ngFor="let entity of entities;" (click)="goToConfigure(entity)">
            <div *ngIf="entity.entityType === 'basicService'; else normalEntity" class="w-100 d-flex align-items-center my-3 py-3 px-2 px-md-5 entity-row mobile-center">
                <div class="text-left">
                    <div class="vertical-center-img img-entity d-flex">
                        <img *ngIf="!entity?.customData && entity?.entityType === 'basicService'" src="{{entity?.logoUrl}}" onerror="this.src='assets/img/basic-services-icons/other.svg'"  style="max-width: 60px; max-height: 60px;" alt="{{ entity?.utilityName}}">
                        <img *ngIf="entity?.customData && entity?.customData.providerName && ['Security Principal', 'Hipotecaria Security', 'Tango', 'Club de Polo San Cristóbal', 'Creditú', 'Edipro'].includes(entity?.customData.providerName)" src="assets/img/entity-icons/{{entity?.customData.providerName}}.svg"  style="width: 60px; height: 60px;" alt="{{ entity?.showEntityTypeLocale()}}">
                    </div>
                </div>
                <div class="ml-2 ml-md-3 text-left">
                  <div class="d-block">
                    <span class="primary-text">{{entity.alias ? entity.utilityName + ' - ' + entity.alias : entity.utilityName}} </span>
                  </div>
                  <div class="d-block">
                    <span class="secundary-text">N° de cuenta: </span> <span class="secundary-text">{{ entity.clientNumber }}</span>
                  </div>
                </div>
            </div>
            <ng-template #normalEntity>
                <div class="w-100 d-flex align-items-center my-3 py-3 px-2 px-md-5 entity-row mobile-center">
                    <div class="text-left">
                        <div class="vertical-center-img img-entity d-flex">
                            <img *ngIf="!entity.customData" src="assets/img/entity-icons/{{entity?.entityType}}.svg" style="max-width: 100%; max-height: 100%;" alt="{{ entity?.showEntityTypeLocale()}}">
                            <img *ngIf="entity?.customData && entity?.customData.providerName && ['Security Principal', 'Hipotecaria Security', 'Tango', 'Club de Polo San Cristóbal', 'Creditú'].includes(entity?.customData.providerName)" src="assets/img/entity-icons/{{entity?.customData.providerName}}.svg" style="max-width: 100%; max-height: 100%;" alt="{{ entity?.showEntityTypeLocale()}}">
                        </div>
                    </div>
                    <div class="ml-2 ml-md-3 text-left">
                      <div class="d-block">
                        <span class="primary-text">{{!entity?.customData?.providerName ? entityTypeLocale[entity.entityType] : entity?.customData?.providerName }} </span>
                      </div>
                      <div *ngIf="entity.referenceAddress" class="d-block">
                       <span class="secundary-text">{{ entity.referenceAddress }}</span>
                      </div>
                      <div *ngIf="entity.category && entity.entityType === 'others'" class="d-block">
                       <span class="secundary-text">{{ entity.category }}</span>
                      </div>
                      <div *ngIf="entity.category && entity.entityType === 'professionals'" class="d-block">
                       <span class="secundary-text">{{ professionalServices[entity.category] }}</span>
                      </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div *ngIf="!hideAddEntityBtn" (click)="goToCreateEntity()" class="w-100 d-flex align-items-center justify-content-center my-3 py-4 px-2 px-md-5 entity-row mobile-center">
        <span class="add-entity-text d-flex" style="cursor: pointer;"><img src="assets/img/icons/add_circle_outline.svg" class="img-entity">Agregar nueva cuenta</span>
    </div>
    <div class="d-flex justify-content-center mt-3">
        <button mat-raised-button (click)="cancel()" class="confirm-btn">Volver</button>
    </div>
</div>